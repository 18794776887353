var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{class:_vm.colWrapperClass,style:(_vm.wrapperStyle)},[(!_vm.hidePrice)?[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"d-flex flex-row-reverse",attrs:{"type":"chip"}}):_c('span',{class:{ watchlistcellgain: _vm.isGain, watchlistcellloss: _vm.isLoss },staticStyle:{"font-weight":"bold","font-size":"2em"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v("\n            "+_vm._s(_vm.latestIEXPrice
                ? _vm.formatCurrency.format(_vm.latestIEXPrice)
                : _vm.formatCurrency.format(_vm.lastCiqPrice))+"\n          ")])]}}],null,false,134147942)},[_vm._v(" "),_c('span',[_vm._v("\n          "+_vm._s(_vm.dateTimeString)+"\n        ")])])],1),_vm._v(" "),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"d-flex flex-row-reverse",attrs:{"type":"table-cell"}}):[_c('br'),_vm._v(" "),_c('span',{staticClass:"price",class:{ watchlistcellgain: _vm.isGain, watchlistcellloss: _vm.isLoss },staticStyle:{"font-weight":"bold","font-size":"1.17em"},style:(`color: ${_vm.gain ? 'green' : 'red'};`)},[_vm._v("\n        "+_vm._s(`${_vm.gain ? "+" : ""}${
            _vm.useIEX
              ? _vm.formatCurrency.format(_vm.latestIEXChange)
              : _vm.formatCurrency.format(_vm.lastCiqChange)
          }`)+"\n      ")]),_vm._v(" "),_c('span',{staticClass:"price",class:{ watchlistcellgain: _vm.isGain, watchlistcellloss: _vm.isLoss },staticStyle:{"font-weight":"bold","font-size":"1.17em"},style:(`color: ${_vm.gain ? 'green' : 'red'};`)},[_vm._v("\n        ("+_vm._s(`${_vm.gain ? "+" : ""}${
            _vm.useIEX
              ? _vm.formatPercent.format(_vm.latestIEXChangePct)
              : _vm.formatPercent.format(_vm.lastCiqChangePercent)
          }`)+")\n      ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticStyle:{"font-size":"0.67em"}},[_vm._v("\n        "+_vm._s(_vm.dateTimeString)+"\n      ")])]]:[_vm._v("\n    "+_vm._s(_vm.$Amplify.I18n.get(
        "Business Owner Mode Activated - All Stock Prices Hidden"
      ))+"\n  ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }